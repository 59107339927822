<!-- eslint-disable default-case -->
<template>
  <b-card no-body class="ft-b-table-filter">
    <b-card-header>
      <b-card-title v-if="title">
        <feather-icon
          icon="BookIcon"
          size="25"
        />{{ title }}</b-card-title>
      <slot name="rightAction" />
      <!--<b-button size="sm" variant="success" :to="{name: 'manager-classes-create'}">
        <feather-icon icon="PlusIcon" />
        Thêm Lớp Học
      </b-button>-->
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col>
          <app-collapse accordion v-if="this.$slots.filterAdvanced">
            <app-collapse-item :isVisible="true" headerStyle="background-color: #d2d6de;padding: 7px">
              <template slot="header">
                <div class="d-flex align-items-center">
                  <b-card-title>
                    <feather-icon
                      size="20"
                      class="mr-1"
                      icon="InfoIcon"
                    />
                    <span>Bộ Lọc Nâng Cao</span>
                  </b-card-title>
                </div>
              </template>
              <div class="ft-filter-advanced">
                <slot name="filterAdvanced" />
              </div>
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <table class="table table-bordered" style="margin-top: 10px" v-if="this.$slots.filterTable">
            <tbody>
              <slot name="filterTable" />
            </tbody>
          </table>
          <b-row>
            <b-col>
              <div class="print">
                <b-button size="sm" disabled><feather-icon icon="PrinterIcon" size="13"/> </b-button>
                <b-button size="sm" variant="info" @click="exportToPrint(printId)">IN</b-button>
                <json-excel
                  :data="rows"
                  :fields="fieldsExportExcel"
                  type="xls"
                  title="XLS"
                />
                <json-excel
                  :data="rows"
                  :fields="fieldsExportExcel"
                  type="csv"
                  title="CSV"
                />
                <b-button size="sm" variant="warning" @click="generatePDF()">PDF</b-button>
              </div>
            </b-col>
          </b-row>
          <div :id="printId">
            <vue-html2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="true"
              :paginate-elements-by-height="1400"
              filename="nightprogrammerpdf"
              :pdf-quality="2"
              :manual-pagination="false"
              pdf-format="a4"
              :pdf-margin="10"
              pdf-orientation="portrait"
              pdf-content-width="800px"
              @progress="onProgress($event)"
              ref="html2Pdf"
            >
              <section slot="pdf-content">
                <b-table
                  :fields="fields"
                  :items.sync="rows"
                  selectable
                  @row-selected="onRowSelected"
                  :filter-included-fields="filterOn"
                  :filters="filter"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  stacked="md"
                  show-empty
                  small
                  @filtered="onFiltered"
                  :busy="isBusy"
                />
              </section>
            </vue-html2pdf>
            <b-table
              :fields="fields"
              :items.sync="rows"
              selectable
              @row-selected="onRowSelected"
              :filter-included-fields="filterOn"
              :filters="filter"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              stacked="md"
              show-empty
              small
              @filtered="onFiltered"
              :busy="isBusy"
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(act)="data">
                <div class="ft-filter-act-btn-margin">
                  <b-button v-if="!data.field.disabledEditBtn" variant="info" size="sm" @click="toEdit(data)"><feather-icon icon="EditIcon" size="20" /></b-button>
                  <b-button v-if="!data.field.disabledDeleteBtn" variant="warning" size="sm" @click="showMsgBoxConfirmDelete(data.index)"><feather-icon icon="XIcon" size="20" /></b-button>
                </div>
              </template>
              <template #cell(price)="data">
                <span style="font-weight: bold;">{{ data.item.price.toLocaleString() }}đ</span>
              </template>
              <template #cell(teacher)="data">
                <span style="font-weight: bold;">{{ data.item.teacher }}</span>
              </template>
              <template #cell(student_percent)="data">
                <b-progress :max="100">
                  <b-progress-bar
                    :variant="perCentT(data.item.student_current, data.item.student_max)['variant']"
                    :value="perCentT(data.item.student_current, data.item.student_max)['percent']"
                    :animated="perCentT(data.item.student_current, data.item.student_max)['percent'] < 100"
                    :label="perCentT(data.item.student_current, data.item.student_max)['percent'] + '%'"
                    show-progress />
                  </b-progress-bar>
                </b-progress>
              </template>
              <!--date-->
              <template #cell(start_date)="data">
                <i>{{ getDateByRow(data.item.start_date) }}</i>
              </template>
              <template #cell(name)="data">
                <b>{{ data.item.name }}</b>
              </template>
              <template #cell(end_date)="data">
                <i>{{ getDateByRow(data.item.end_date)}}</i>
              </template>
              <template #cell(createdAt)="data">
                <i>{{ getDateByRow(data.item.createdAt)}}</i>
              </template>
              <template #cell(created_at)="data">
                <i>{{ getDateByRow(data.item.created_at)}}</i>
              </template>
              <template #cell(hour)="data">
                {{ getHourByRow(data.item.hour)}}
              </template>
              <template #cell(color)="data">
                <b-badge :style="'background-color:' + data.item.color">{{ getColorByRow(data.item.color )}}</b-badge>
              </template>
              <template #cell(status)="data">
                <span v-if="data.item.status" style="color: green">
                  Hoàn Thành
                </span>
                <span v-else>
                  Chưa Hoàn Thành
                </span>
              </template>
              <template #cell(order_status)="data">
                <span v-if="data.item.status" style="color: green">
                  Hoàn Thành
                </span>
                <span v-else>
                  Chưa Hoàn Thành
                </span>
              </template>
            </b-table>
          </div>
          <b-row style="margin-top: 2rem" v-if="totalRows > perPage">
            <b-col cols="1">
              <b-form-group
                class="mb-0"
              >
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <div style="float:right">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rowsLength"
                  :per-page="perPage"
                  aria-controls="my-table"
                />
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>

import VueHtml2pdf from "vue-html2pdf";
import {BBadge, BProgressBar, BProgress, BFormGroup, BFormSelect, BSpinner, BButton, BCol, BRow, BCardBody, BTable, BCardTitle, BPagination, BCard, BCardHeader,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { formatDate } from '@/@core/utils/filter'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import JsonExcel from './jsonExcel.vue'


export default {
  props: {
    printId: {
      type: String,
      default: 'b-table',
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
    fields: {
      type: Array,
      required: true,
      default: () => [],
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    pageLength: {
      type: Number,
      default: 10,
    },
    totalRows: {
      type: Number,
      default: 1,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    pageOptions: {
      type: Array,
      default: () => [5, 10, 15, { value: 100, text: "Show a lot" }],
    },
    sortBy: {
      type: String,
      default: '',
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
    sortDirection: {
      type: String,
      default: 'asc',
    },
    filter: {
      type: String,
      default: null,
    },
    filterOn: {
      type: Array,
      default: () => [],
    },
    filterDateFrom: {
      type: String,
      default: null,
    },
    filterDateTo: {
      type: String,
      default: null,
    },
    filterDateCreatedFrom: {
      type: String,
      default: null,
    },
    filterDateCreatedTo: {
      type: String,
      default: null,
    },
  },
  components: {
    VueHtml2pdf,
    BBadge,
    BProgressBar,
    BProgress,
    BFormGroup,
    BFormSelect,
    BSpinner,
    AppCollapse,
    AppCollapseItem,
    BButton,
    BCol,
    BRow,
    BCardBody,
    BTable,
    BPagination,
    BCardHeader,
    BCardTitle,
    BCard,
    FeatherIcon,
    JsonExcel,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields_export_excel: {},
      selected: [],
    }
  },
  computed: {
    fieldsExportExcel() {
      const result = {}
      for (let i = 0; i < this.fields.length; i++) {
        result[this.fields[i].label] = this.fields[i].key
      }
      return result
    },
    rowsLength() {
      return this.items.length
    },
    rows() {
      if (this.selectedRows) {
        return this.items.filter(item => !this.selectedRows.includes(item))
      // eslint-disable-next-line no-else-return
      } else if (this.filterDateFrom && this.filterDateTo) {
        const start = Math.round(new Date(this.filterDateFrom).getTime()/1000)
        const end = Math.round(new Date(this.filterDateTo).getTime()/1000)
        return this.items.filter(item => {
          return item.start_date >= start && item.end_date <= end
        })
      }else if (this.filterDateCreatedFrom && this.filterDateCreatedTo) {
        const start = Math.round(new Date(this.filterDateCreatedFrom).getTime()/1000)
        const end = Math.round(new Date(this.filterDateCreatedTo).getTime()/1000)
        return this.items.filter(item => {
          return item.createdAt >= start && item.createdAt <= end
        })
      }
      return this.items
    },
  },
  methods: {
    onProgress(event) {
      console.log(`Processed: ${event} / 100`);
    },
    hasGenerated() {
      alert("PDF generated successfully!");
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    exportToPrint(e) {
      const printContents = document.getElementById(e).innerHTML
      const originalContents = document.body.innerHTML
      document.body.innerHTML = printContents
      window.print()
      document.body.innerHTML = originalContents
      return true
    },
    getColorByRow(color) {
      return color
    },
    perCentT(current, max) {
      const percent = ((current * 100) / max).toFixed(0)
      return {
        percent,
        variant: this.variantPercent(percent)
      }
    },
    variantPercent(perCent) {
      let variant = 'info'
      // eslint-disable-next-line keyword-spacing
      if(perCent < 10) variant = 'warning'
      else if (perCent < 50) variant = 'primary'
      else if (perCent < 75) variant = 'info'
      else if (perCent <= 100) variant = 'success'
      return variant
    },
    statusVariant(status) {
      return status ? '<span style="border: 2px solid green">Hoàn Thành</span>' : 'Chưa Hoàn Thành'
    },
    toEdit(data) {
      this.$emit('to-edit', data)
    },
    getDateByRow(unixTimestamp) {
      return formatDate(unixTimestamp * 1000, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
    },
    getHourByRow(unixTimestamp) {
      return unixTimestamp
    },
    onFiltered(filteredItems) {
      // console.log('onFilter', filteredItems)
      // this.items = filteredItems
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onRowSelected(rows) {
      this.count = rows.length
      this.selected = rows
    },
    showMsgBoxConfirmDelete(id) {
      // delete row
      this.$bvModal
        .msgBoxConfirm('Bạn chắc chắn muốn xóa?', {
          title: 'Xác nhận',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Có',
          cancelTitle: 'Không',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            // xóa dòng
            this.items = this.items.filter((r, k) => k !== id)
            this.$emit('remove-act', id, this.items)
          }
        })
    },
  },
}
</script>

<style lang="scss" >
// HTML

.ft-b-table-filter  {
  .form-control, .page-item:first-child:not(.prev-item) .page-link, .b-form-datepicker, .vs__dropdown-toggle, button, a,  .card-header:first-child, .custom-select {
    border-radius: 0px!important
  }
  ::placeholder, .text-muted {
      color: #9a92ad!important;
      font-size: 1rem!important;
  }
}
</style>

<style scoped>
  .print {
    text-align: right; margin: 5px 2px 5px 0px
  }
  .ft-class-list-opt{
    margin-right: 10px;
  }
  .ft-filter-act-btn-margin button{
    margin-right: 5px;
  }
  .table-bordered {
    padding-bottom: 10px!important;
  }
  
</style>
<style >
</style>