<template>
  <div class="ft-top-margin">
    <app-collapse accordion>
      <app-collapse-item :isVisible="true" headerStyle="background-color: #d2d6de" >
        <template slot="header">
          <div class="d-flex align-items-center">
            <b-card-title class="ml-25">
              <feather-icon
                size="24"
                class="mr-1"
                icon="InfoIcon"
              />
              <span>Thời Khóa Biểu</span>
            </b-card-title>
          </div>
        </template>
        <full-calendar
          ref="calendarRef"
          class="calendar"
          :options="calendarOptions"
        />
      </app-collapse-item>
    </app-collapse>
    <b-modal @ok="ok" size="lg" title="Cài Đặt Thời Khóa Biểu" id="modal-schedule-time">

      <b-form-group
        label="Tiêu Đề"
        label-cols="2"
      >
        <b-form-input
          v-model="form.title"
        />
      </b-form-group>
      <b-form-group
        label="Tùy chỉnh màu"
        label-cols="2"
      >
        <validation-provider
          #default="{ errors }"
          rules="required"
          name="Colour">
            <color-picker :color="form.color" v-model="form.color" />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        label="Thời Gian"
        label-cols="2"
      >
        <b-row no-gutters>
          <b-col cols="6">
            <div class="ft-right-padding">
              <flat-pickr
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                class="form-control"
                placeholder="Bắt Đầu"
                name="dateNtim"
                v-model="form.start"
              />
            </div>
          </b-col>
          <b-col cols="6">
            <flat-pickr
              :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
              class="form-control"
              placeholder="Kết thúc"
              name="dateNtim"
              v-model="form.end"
            />
          </b-col>
        </b-row>
      </b-form-group>
      <b-form-group
        label="Ghi Chú"
        label-cols="2"
      >
        <b-form-textarea v-model="form.note" />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'

import { ValidationProvider} from 'vee-validate'

import ColourPicker from 'vue-colour-picker'
import { BCol, BRow, BCardTitle, BModal, BFormGroup, BFormInput, BFormTextarea, } from 'bootstrap-vue'
import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/list/main.css";
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'
import ColorPicker from '@/@core/components/form/colorPicker.vue';

export default {
  methods: {
    handleDateClick(date) {
      this.id += 1
      this.$set(this, 'form', {
        title: `${date.dateStr}`,
        id: this.id,
        start: date.dateStr,
        startTimeStamp: Math.round(new Date(date.dateStr).getTime() / 1000),
        endTimeStamp: Math.round(new Date(date.dateStr).getTime() / 1000),
        end: date.dateStr, // rong nếu bằng start
        color: this.form.color,
        note: this.form.note,
      })
      this.$set(this, 'edit', false)
      // backgroundColor, borderColor, textColor, url
      this.$bvModal.show('modal-schedule-time')
    },
    eventClick(date) {
      // chỉnh sửa lấy thông tin bằng find title, startTimeStamp, endTimeStamp,
      console.log('date', date)
      // khong lay gia tri form ma lay gia tri default
      this.found = this.calendarOptions.events.findIndex(item => item.id == date.event.id)
      this.$set(this, 'form', {
        title: date.event.title,
        id: date.event.id,
        start: date.event.startStr,
        startTimeStamp: Math.round(new Date(date.event.startStr).getTime() / 1000),
        endTimeStamp: date.event.endStr !== '' ? Math.round(new Date(date.event.endStr).getTime() / 1000) : '',
        end: date.event.endStr, // lấy thông tin bằng events this
        color: date.event.backgroundColor,
        note: this.calendarOptions.events[this.found].note,
      })
      this.$set(this, 'edit', true)
      // tìm sau đó filter
      this.$bvModal.show('modal-schedule-time')
    },
    ok() {
      console.log('ok form', this.form)
      // edit thì k update
      if (this.edit === false) {
        // thêm nhưng sắp xếp theo thời gian
        this.calendarOptions.events.push({ ...this.form })
        this.calendarOptions.events.sort((a, b) => a.startTimeStamp - b.startTimeStamp)
        // gửi lên server tạo mới
      } else {
        this.edit = false
        this.found = this.calendarOptions.events.findIndex(item => item.id == this.form.id)
        this.calendarOptions.events[this.found] = this.form
        // gửi lên server edit
        this.$set(this.calendarOptions.events, this.found, this.form)
      }
      this.form = {}
    },
  },
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {
        return {
          id: 1,
        }
      },
    },
    events: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      id: 1,
      found: -1,
      edit: false,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, listPlugin, timeGridPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: "today prev,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        },
        events: [
        ],
        locale: 'vn',
        eventClick: this.eventClick,
        weekNumbers: true,
        dateClick: this.handleDateClick,
        eventDisplay: 'block',
        displayEventTime: false,
        allDaySlot: false,
        buttonText: {
          today: 'Hôm Nay',
          month: 'Tháng',
          week: 'Tuần',
          day: 'Ngày',
          list: 'Danh Sách',
        },
      },
    }
  },
  components: {
    ColorPicker,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    "colour-picker": ColourPicker,
    flatPickr,
    BCol,
    BRow,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BModal,
    FullCalendar,
    BCardTitle,
},
}
</script>

<style>

</style>