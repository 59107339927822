<template>
  <div>
    <b-container
      fluid
    >
      <b-tabs>
        <b-tab title="Tổng Số Giáo Viên">
          <b-table-filter
            :items="itemsTeacherManager"
            :fields="fieldsTeacherManager"
            title="Danh Sách Giáo Viên"
            :totalRows="rowsLength"
            :currentPage="currentPage"
            :perPage="perPage"
            :filterOn="filterOn"
            :filter="filter"
            @remove-act="removeItem"
          >
            <template #rightAction>
              <b-button variant="success" :to="{ name: 'teacher-create'}"><feather-icon icon="PlusIcon" />Thêm Giáo Viên</b-button>
            </template>
            <template #filterAdvanced>
              <b-row>
                <b-col cols="4">
                  <b-row no-gutters>
                    <b-col cols="8">
                      <b-row no-gutters>
                        <b-col>
                          <b-form-group
                            label="Tìm Giáo Viên"
                          >
                            <div class="ft-class-list-opt">
                              <b-form-input @change="filterAdvanced('teacher', $event)" placeholder="Tìm..."/>
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="4" offset="4">
                  <b-row no-gutters>
                    <b-col>
                      <div class="ft-class-list-opt">
                        <b-form-group
                          label="Thời Gian Bắt Đầu"
                        >
                          <b-form-datepicker
                            v-model="filterDateFrom"
                            @input="columnFilter('tu_ngay')"
                            placeholder="Từ Ngày"
                            :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                            :date-disabled-fn="dateDisabled"
                          />
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col>
                      <div class="ft-class-list-opt">
                        <b-form-group
                          label="Thời Gian Kết Thúc"
                        >
                          <b-form-datepicker
                            v-model="filterDateTo"
                            @input="columnFilter('den_ngay')"
                            placeholder="Đến Ngày"
                            :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                            :date-disabled-fn="dateDisabled"
                          />
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </template>
            <template #filterTable>
              <td style="width: 15%">
                  <b-form-input size="sm"
                    class="ft-search-form-them-khoa-hoc"
                    placeholder="Tìm Tên Giáo Viên"
                    @keyup="columnFilter('teacher')"
                    v-model="filterKhoaHoc"
                  />
              </td>
              <td style="width: 40%"></td>
              <td>
                  <b-form-datepicker  size="sm"
                    v-model="filterDateFrom"
                    @input="columnFilter('tu_ngay')"
                    placeholder="Từ Ngày"
                    :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                  />
              </td>
              <td>
                  <b-form-datepicker size="sm"
                    v-model="filterDateTo"
                    @input="columnFilter('den_ngay')"
                    placeholder="Đến Ngày"
                    :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                    :date-disabled-fn="dateDisabled"
                  />
              </td>
            </template>
          </b-table-filter>
        </b-tab>
        <b-tab title="Quản Lý Lớp">
          <b-table-filter
            :items="items"
            :fields="fields"
            title="Danh Sách Giáo Viên"
            :totalRows="rowsLength"
            :currentPage="currentPage"
            :perPage="perPage"
            :filterOn="filterOn"
            :filter="filter"
            @remove-act="removeItem"
          >
            <template #filterAdvanced>
              <b-row>
                <b-col cols="4">
                  <b-row no-gutters>
                    <b-col cols="12">
                      <b-row no-gutters>
                        <b-col>
                          <b-form-group
                            label="Tìm Mã Lớp"
                          >
                            <div class="ft-class-list-opt">
                              <b-form-input @change="filterAdvanced('classCode', $event)" placeholder="Tìm..."/>
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="4" offset="4">
                  <b-row no-gutters>
                    <b-col>
                      <div class="ft-class-list-opt">
                        <b-form-group
                          label="Từ"
                        >
                          <b-form-datepicker
                            v-model="filterDateCreatedFrom"
                            @input="columnFilter('tu_ngay')"
                            placeholder="Từ Ngày"
                            :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                          />
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col>
                      <div class="ft-class-list-opt">
                        <b-form-group
                          label="Đến"
                        >
                          <b-form-datepicker
                            v-model="filterDateCreatedTo"
                            @input="columnFilter('den_ngay')"
                            placeholder="Đến Ngày"
                            :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                            :date-disabled-fn="dateDisabled"
                          />
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </template>
            <template #filterTable>
              <td style="width: 15%">
                  <b-form-input size="sm"
                    class="ft-search-form-them-khoa-hoc"
                    placeholder="Tìm Mã Lớp Học"
                    @keyup="columnFilter('classCode')"
                    v-model="filterType['classCode']"
                  />
              </td>
              <td style="width: 50%">
              </td>
              <td>
                <b-form-datepicker  size="sm"
                  v-model="filterDateFrom"
                  @input="columnFilter('tu_ngay')"
                  placeholder="Từ Ngày"
                  :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                />
              </td>
              <td>
                  <b-form-datepicker size="sm"
                    v-model="filterDateTo"
                    @input="columnFilter('den_ngay')"
                    placeholder="Đến Ngày"
                    :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                    :date-disabled-fn="dateDisabled"
                  />
              </td>
            </template>
          </b-table-filter>
        </b-tab>
        <b-tab active title="Thời Khóa Biểu">
          <schedule-time :form="form" />
        </b-tab>
      </b-tabs>
    </b-container>
  </div>
</template>

<script>

import {
  BTabs, BTab, BFormGroup, BButton, BCol, BRow, BFormInput, BFormDatepicker, BContainer, 
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import scheduleTime from '@/views/components/scheduleTime.vue'
import BTableFilter from '@/@core/components/datatable/BTableFilter.vue'

export default {
  created() {
    this.busy = true
    this.$http.get('/teacher')
      .then(res => {
        this.items = [];
        this.busy = false
        console.log('created', res)
      })
  },
  components: {
    scheduleTime,
    BTabs,
    BTab,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BCol,
    BRow,
    FeatherIcon,
    BTableFilter,
    BContainer,
    BButton,
},
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {},
      fieldsTeacherManager: [
        {
          key: 'teacher',
          label: 'Tên Giáo Viên Dạy'
        },
        {
          key: 'classCount',
          label: 'Số Lớp Dạy'
        },
        {
          key: 'studentCount',
          label: 'Học Sinh Đang Học'
        },
        {
          key: 'lessonCountWeek',
          label: 'Tiết Học Trong Tuân'
        }
      ],
      busy: false,
      pageLength: 10,
      dir: false,
      fields: [
        {
          label: 'Mã Lớp Học',
          key: 'classCode',
        },
        {
          label: 'Teacher',
          key: 'teacher',
        },
        {
          label: 'Số Học Sinh',
          key: 'studentCount',
        },
        {
          label: 'Số Buổi Đã Học',
          key: 'courseCount',
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterKhoaHoc: null,
      filterLopHoc: null,
      filterGiaoVien: null,
      filterTeacherAt: null,
      filter: null,
      filterType: {},
      filterOn: [], // danh sách cột
      count: 0,
      filterDateFrom: null,
      filterDateTo: null,
      selected: [],
      itemsTeacherManager: [
        {
          id: 4,
          classCount: 1,
          teacher: 'XXXXX',
          studentCount: 10,
          lessonCountWeek: 1,
        },
      ],
      items: [
        {
          id: 4,
          classCode: '1',
          teacher: 'XXXXX',
          studentCount: 10,
          courseCount: '10/20',
          createdAt: 1658925621,
        },
      ],
    }
  },
  mounted() {
    document.title = `Quản lý Lớp | ${this.router.meta.pageTitle}`
  },
  computed: {
    rowsLength() {
      return this.items.length;
    },
  },
  methods: {
    removeItem(id, k) {
      console.log('remove', id, k)
    },
    filterAdvanced(t, $event) {
    },
    dateDisabled(ymd, date) {
      const day = date.getDate()
      return day < new Date(new Date(this.filterDateFrom).getDate())
    },
    columnFilter(t) {
      this.filterOn = [t]
      this.filter = this.filterType[t]
    },
    onFiltered(filteredItems) {
      // console.log('onFilter', filteredItems)
      // this.items = filteredItems
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onRowSelected(rows) {
      this.count = rows.length
      this.selected = rows
    },
  },
}
</script>

<style lang="scss" >
  @import '@core/scss/vue/libs/vue-select.scss';
// HTML
.card {
  .card-header .heading-elements {
    position: static;
    background: red;
  }
}
</style>
<style>
.ft-class-list-opt{
  margin-right: 10px;
}
</style>