<template>
  <downloadexcel
    class="btn btn-success btn-sm"
    :data="data"
    :fields="fields"
    :type="type"
  >
    {{ title }}
  </downloadexcel>
</template>

<script>
import downloadexcel from "vue-json-excel";

export default {
  props: {
    title: {
      type: String,
      default: 'Export',
    },
    type: {
      type: String,
      default: 'xls',
    },
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    fields: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    downloadexcel,
  },
  data() {
    return {
    }
  }, // data
}
</script>